.scroll-to-top {
  position: sticky;
  bottom: 0.7rem;
  right: 0.7em;
  animation: fadeInP 700ms ease-in-out 1s both;
  ;
  cursor: pointer;
}

.modal-scroll-to-top {
  position: absolute;
  bottom: 1rem;
  right: 0.7rem;
  animation: fadeInP 700ms ease-in-out 1s both;
  ;
  cursor: pointer;
  z-index: 15000;
}

table td:first-child {
  position: sticky;
  left: 0;
  background-color: #fff;
}

table th:first-child {
  position: sticky;
  left: 0;
  background-color: #fff;
}

@keyframes fadeInP {
  from {
    opacity: 0;
  }

  to {
    opacity: 0.7;
  }
}

.handpointer-mobile {
  position: absolute;
  opacity: 1;
  z-index: 30;
  margin-top: -15px;
  /*transform: scale(0.2);
  animation: move-chevron 3s ease-out 3;*/
}

.chevron-mobile {
  position: absolute;
  width: 0.3rem;
  height: 1.0rem;
  opacity: 0;
  z-index: 30;
  margin-top: -15px;
  transform: scale(0.2);
  animation: move-chevron-mobile 3s ease-out 2;
}

.chevron-mobile:first-child {
  animation: move-chevron-mobile 3s ease-out 1s 2;
}

.chevron-mobile:nth-child(2) {
  animation: move-chevron-mobile 3s ease-out 2s 2;
}

.chevron-mobile:before,
.chevron-mobile:after {
 content: '';
 position: absolute;
 top: 0;
 height: 50%;
 width: 100%;
 background: #2c3e50;
}

.chevron-mobile:before {
 top: 0;
 transform: skewX(-30deg);
}

.chevron-mobile:after {
 top: 50%;
 height: 50%;
 transform: skewX(30deg);
}

@keyframes move-chevron-mobile {
 25% {
  opacity: 1;
	}
 33.3% {
  opacity: 1;
  transform: translateX(-36px);
 }
 66.6% {
  opacity: 1;
  transform: translateX(-50px);
 }
 100% {
  opacity: 0;
  transform: translateX(-77px) scale(0.5);
 }
}

.handpointer-web {
  position: absolute;
  opacity: 1;
  z-index: 30;
  margin-top: -15px;
  margin-left: 130px;
  /*transform: scale(0.2);
  animation: move-chevron 3s ease-out 3;*/
}

.chevron-web {
  position: absolute;
  width: 0.5rem;
  height: 1.5rem;
  opacity: 0;
  z-index: 30;
  margin-top: -15px;
  margin-left: 130px;
  transform: scale(0.2);
  animation: move-chevron-web 3s ease-out 2;
}

.chevron-web:first-child {
  animation: move-chevron-web 3s ease-out 1s 2;
}

.chevron-web:nth-child(2) {
  animation: move-chevron-web 3s ease-out 2s 2;
}

.chevron-web:before,
.chevron-web:after {
 content: '';
 position: absolute;
 top: 0;
 height: 50%;
 width: 100%;
 background: #2c3e50;
}

.chevron-web:before {
 top: 0;
 transform: skewX(30deg);
}

.chevron-web:after {
 top: 50%;
 height: 50%;
 transform: skewX(-30deg);
}

@keyframes move-chevron-web {
 25% {
  opacity: 1;
	}
 33.3% {
  opacity: 1;
  transform: translateX(36px);
 }
 66.6% {
  opacity: 1;
  transform: translateX(50px);
 }
 100% {
  opacity: 0;
  transform: translateX(77px) scale(0.5);
 }
}

.zoom-icon {
  transition: transform .2s; /* Animation */
}

.zoom-icon:hover {
  transform: scale(1.3); /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
}

.has-tooltip-multiline::before {
  white-space: pre-wrap !important;
}
